"use client"

import React, { useCallback } from "react"
import { Session } from "next-auth"
import Image from "next/image"
import { signOut } from "next-auth/react"
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu"
import Link from "next/link"

interface UserNavigationProps {
  user: Session["user"]
}

const UserNavigationComponent: React.FC<UserNavigationProps> = ({ user }) => {
  // サインアウトのハンドラを useCallback でメモ化
  const handleSignOut = useCallback(async (event: Event) => {
    event.preventDefault()
    await signOut({ callbackUrl: "/" })
  }, [])

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <div className="relative w-10 h-10 flex-shrink-0">
          <Image
            src={user?.image || "/default.png"}
            className="rounded-full object-cover"
            alt={user?.name || "avatar"}
            fill
            priority
          />
        </div>
      </DropdownMenuTrigger>

      <DropdownMenuContent className="p-2 w-[300px]" align="end">
        <Link href={`/author/${user?.id}`}>
          <DropdownMenuItem className="cursor-pointer">
            <div className="break-words min-w-0">
              <div className="mb-2">{user?.name || ""}</div>
              <div className="text-gray-500">{user?.email || ""}</div>
            </div>
          </DropdownMenuItem>
        </Link>

        <DropdownMenuSeparator />

        {user?.isAdmin && (
          <Link href="/post/new">
            <DropdownMenuItem className="cursor-pointer">
              新規投稿
            </DropdownMenuItem>
          </Link>
        )}

        <Link href="/settings/profile">
          <DropdownMenuItem className="cursor-pointer">
            アカウント設定
          </DropdownMenuItem>
        </Link>

        <DropdownMenuItem
          onSelect={handleSignOut}
          className="text-red-600 cursor-pointer"
        >
          ログアウト
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  )
}

const UserNavigation = React.memo(UserNavigationComponent)

export default UserNavigation
